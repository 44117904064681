import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >

<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m5655 5300 c198 -10 489 -39 595 -60 30 -6 93 -18 140 -26 447 -79 959
-251 1385 -466 725 -366 1315 -849 1825 -1494 61 -77 266 -371 321 -461 960
-1554 1055 -3498 249 -5128 -232 -470 -487 -847 -820 -1215 -48 -52 -97 -108
-111 -124 -59 -70 -367 -350 -518 -470 -645 -514 -1406 -876 -2186 -1039 -295
-62 -444 -84 -705 -107 -539 -47 -1139 3 -1696 140 -645 160 -1290 458 -1824
843 -69 49 -127 93 -130 96 -3 4 -25 22 -50 41 -392 300 -824 764 -1108 1190
-20 30 -39 57 -42 60 -10 9 -134 212 -187 305 -164 289 -323 647 -419 942 -49
151 -120 407 -133 478 -6 33 -16 76 -21 95 -6 19 -22 103 -36 185 -183 1075
-48 2127 400 3123 198 439 516 929 849 1306 183 207 476 484 672 637 39 29 76
59 83 66 63 60 425 297 627 410 668 375 1417 603 2180 663 260 20 409 22 660
10z"/>
<path d="M5285 10133 c-668 -23 -1291 -178 -1918 -477 -104 -50 -312 -164
-358 -196 -15 -11 -30 -20 -34 -20 -3 0 -59 -35 -123 -77 -579 -386 -1072
-896 -1433 -1483 -311 -507 -543 -1134 -634 -1714 -36 -231 -47 -371 -52 -671
-5 -330 6 -518 48 -788 110 -717 383 -1399 800 -1999 197 -283 527 -645 794
-873 988 -841 2257 -1239 3545 -1110 425 42 915 163 1280 315 41 17 104 43
140 57 105 43 416 204 530 274 58 36 112 68 120 72 22 10 226 154 315 222 293
225 622 548 842 826 115 146 292 404 368 537 396 693 608 1412 643 2182 38
818 -139 1625 -518 2365 -227 443 -493 808 -855 1171 -274 275 -498 456 -809
654 -314 199 -619 347 -961 467 -134 47 -144 50 -330 103 -206 57 -498 114
-725 139 -105 12 -492 32 -555 29 -22 -1 -76 -3 -120 -5z m420 -68 c279 -10
630 -66 947 -151 941 -252 1752 -774 2398 -1544 177 -212 411 -571 550 -848
454 -898 604 -1917 431 -2912 -96 -548 -305 -1112 -584 -1575 -19 -31 -200
-309 -209 -320 -5 -5 -44 -57 -87 -115 -233 -310 -583 -661 -876 -880 -33 -25
-62 -48 -65 -51 -3 -3 -62 -44 -131 -91 -429 -292 -855 -492 -1359 -638 -664
-191 -1414 -229 -2091 -105 -257 47 -390 80 -614 152 -699 224 -1338 614
-1850 1127 -479 481 -814 990 -1059 1616 -209 531 -316 1101 -316 1685 0 617
106 1161 347 1775 36 94 174 376 245 505 139 251 336 534 522 749 76 89 272
299 296 317 12 9 49 43 83 76 318 303 827 644 1218 813 318 138 543 216 814
284 215 53 418 90 650 117 121 13 421 27 525 22 41 -1 138 -5 215 -8z"/>
<path d="M6265 9219 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M4540 9090 c-14 -4 -70 -18 -125 -31 -209 -46 -411 -153 -545 -288
-45 -46 -130 -151 -146 -182 -23 -46 -11 -45 21 2 78 112 255 240 431 310 278
112 702 129 1024 39 155 -43 330 -126 449 -212 90 -67 264 -246 321 -333 59
-89 146 -266 180 -365 12 -36 37 -139 55 -229 19 -90 40 -170 47 -177 19 -18
100 -26 150 -15 72 16 124 101 62 101 -29 0 -139 45 -166 68 -10 8 -18 29 -19
46 0 40 -22 142 -32 152 -4 4 -7 20 -7 36 0 60 -98 308 -162 413 -89 143 -103
161 -223 280 -133 131 -271 223 -434 286 -42 16 -85 34 -96 39 -11 4 -33 11
-50 15 -16 3 -48 10 -70 16 -22 5 -62 14 -90 20 -61 14 -540 22 -575 9z"/>
<path d="M3059 8304 c-32 -85 -42 -115 -60 -174 -49 -162 -71 -266 -86 -415
-30 -311 18 -675 129 -962 23 -59 50 -124 61 -143 11 -19 33 -59 49 -89 29
-54 106 -171 118 -181 3 -3 17 -21 30 -40 68 -97 233 -259 385 -378 104 -81
95 -74 135 -102 19 -14 37 -27 40 -31 3 -3 34 -25 70 -50 36 -24 67 -46 70
-49 4 -5 116 -78 350 -227 19 -13 40 -27 47 -33 7 -5 15 -10 18 -10 3 0 52
-28 108 -63 147 -92 167 -102 222 -123 135 -49 206 -95 314 -202 58 -58 117
-112 131 -121 119 -74 439 -346 521 -442 180 -212 295 -436 334 -653 14 -80
17 -128 12 -245 -7 -139 -15 -192 -51 -325 -9 -33 -14 -61 -12 -63 7 -7 77
190 93 260 56 252 39 506 -47 702 -9 22 -23 57 -29 77 -7 21 -17 40 -22 43 -5
4 -22 33 -38 65 -24 49 -76 126 -101 150 -3 3 -25 29 -49 58 -55 67 -111 123
-203 204 -40 35 -81 71 -93 82 -11 10 -83 65 -160 122 -136 102 -183 143 -273
240 -54 58 -154 117 -254 149 -44 14 -116 50 -167 82 -50 32 -120 76 -156 98
-36 22 -129 82 -206 133 -78 50 -143 92 -146 92 -4 0 -134 91 -273 192 -177
128 -412 343 -486 443 -16 22 -34 45 -40 50 -20 19 -64 84 -64 94 0 6 -4 11
-9 11 -5 0 -11 8 -15 18 -3 9 -19 40 -36 67 -39 64 -140 274 -140 291 0 3 -6
20 -14 37 -8 18 -19 57 -25 87 -6 30 -18 80 -26 110 -68 246 -66 767 4 985 53
168 62 198 57 203 -3 2 -10 -8 -17 -24z"/>
<path d="M4295 7328 c7 -32 7 -33 -12 -15 -20 17 -21 17 -40 -24 l-20 -43 21
-39 c28 -53 77 -94 386 -317 26 -19 68 -47 92 -62 23 -15 52 -33 63 -40 10 -7
62 -40 115 -74 52 -33 121 -77 153 -97 31 -20 59 -37 61 -37 2 0 34 -18 71
-41 65 -39 134 -69 160 -69 7 0 26 27 44 59 30 58 31 62 31 194 0 153 2 159
72 178 29 7 253 9 757 6 624 -3 725 -6 786 -21 39 -9 102 -23 142 -32 135 -28
273 -104 376 -208 137 -136 213 -270 294 -515 45 -138 62 -169 95 -176 17 -4
53 -4 82 -1 51 7 52 8 85 68 l34 61 -7 176 c-21 525 -26 636 -36 846 -6 127
-13 236 -15 243 -4 9 -393 12 -1900 12 l-1897 0 7 -32z"/>
<path d="M5305 6270 c17 -16 46 -33 65 -36 l35 -7 -25 19 c-31 24 -85 54 -96
54 -5 0 5 -13 21 -30z"/>
<path d="M7693 5478 c-17 -23 -52 -122 -58 -168 -14 -95 -37 -192 -56 -237
-30 -71 -14 -63 20 10 37 80 69 186 86 292 7 44 16 88 20 98 8 20 2 23 -12 5z"/>
<path d="M4545 5064 c-19 -38 -30 -82 -35 -133 -5 -42 -7 -586 -5 -1209 l3
-1134 -47 7 c-460 66 -897 386 -1121 820 -91 176 -158 442 -175 690 -3 49 -9
93 -14 98 -5 5 -53 10 -107 10 l-98 2 -35 -60 -36 -60 1 -909 c1 -756 4 -910
15 -917 8 -5 55 -9 105 -9 104 0 103 0 170 109 51 85 81 115 128 131 66 21
154 1 526 -123 107 -36 222 -73 255 -82 33 -9 73 -21 89 -26 16 -5 42 -9 58
-9 16 0 27 -3 25 -8 -3 -4 7 -8 21 -9 66 -4 173 -32 200 -52 39 -28 44 -58 40
-271 l-3 -165 -210 -6 c-115 -3 -211 -6 -211 -7 -1 -1 -17 -30 -35 -64 -28
-54 -32 -74 -33 -140 -1 -51 3 -81 12 -90 10 -10 175 -14 827 -16 448 -1 836
1 862 5 46 8 49 11 80 68 28 52 32 70 33 136 0 113 10 109 -263 109 l-227 0 0
1238 c0 680 3 1247 6 1259 5 16 16 23 47 28 95 14 95 14 128 73 l32 56 -91 87
c-49 47 -127 115 -173 150 -46 34 -86 66 -89 69 -26 34 -586 410 -610 410 -12
0 -27 -19 -45 -56z"/>
<path d="M6898 4374 c-6 -5 -1 -68 7 -91 3 -9 26 -13 68 -13 83 0 263 -33 321
-58 169 -75 286 -222 331 -417 3 -16 13 -86 20 -155 8 -70 17 -135 21 -144 13
-33 70 -44 135 -26 26 7 41 21 58 50 l22 40 -75 0 c-81 0 -90 5 -92 55 -3 72
-16 205 -26 260 -11 69 -54 192 -74 216 -8 8 -14 19 -14 22 0 4 -12 22 -27 40
-112 131 -203 179 -394 207 -128 19 -269 26 -281 14z"/>
<path d="M6997 3336 c-70 -19 -101 -45 -105 -89 -2 -20 2 -45 8 -56 14 -27 87
-48 198 -56 104 -9 127 -18 98 -40 -26 -20 -126 -20 -152 0 -22 16 -154 21
-154 5 0 -28 28 -62 67 -81 37 -18 63 -21 156 -22 136 0 196 20 223 75 37 79
-25 122 -193 134 -114 8 -136 15 -113 38 25 24 139 25 164 1 10 -10 35 -15 80
-15 75 0 84 13 43 63 -41 51 -206 73 -320 43z"/>
<path d="M7381 3218 c5 -144 14 -164 94 -199 76 -34 211 -31 282 5 74 37 83
60 83 201 l0 115 -65 0 -65 0 0 -97 c0 -104 -3 -114 -37 -132 -44 -23 -134
-10 -155 23 -4 6 -8 55 -8 109 l0 97 -66 0 -66 0 3 -122z"/>
<path d="M7900 3169 l0 -170 199 3 c195 3 200 4 225 27 36 34 36 91 0 125 -25
23 -26 25 -10 47 35 51 11 111 -52 129 -20 5 -110 10 -199 10 l-163 0 0 -171z
m303 77 c10 -26 -17 -36 -95 -36 -77 0 -78 0 -78 25 0 25 1 25 84 25 62 0 85
-4 89 -14z m16 -125 c8 -5 11 -16 8 -25 -5 -13 -24 -16 -102 -16 -95 0 -95 0
-95 25 0 25 1 25 88 25 48 0 93 -4 101 -9z"/>
<path d="M6563 3113 c-39 -62 -171 -222 -233 -283 -171 -169 -311 -271 -500
-366 -133 -66 -190 -88 -202 -76 -5 5 -8 86 -6 180 2 95 1 172 -3 172 -3 0
-16 -21 -29 -47 -20 -42 -24 -66 -28 -193 -2 -90 0 -155 7 -172 16 -43 59 -37
181 23 163 79 297 171 423 289 27 25 53 47 58 48 30 11 329 373 329 398 0 4 7
16 17 26 9 10 14 21 10 24 -3 3 -14 -7 -24 -23z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
